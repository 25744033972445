import * as React from "react"
import {useLayoutEffect} from "react"

const NotFoundPage = () => {

    useLayoutEffect(() => {
        window.location.href = '/';
    });

    return null;
}

export default NotFoundPage
